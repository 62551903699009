// Configuration
@import "bootstrap/scss/functions";

@import "./_variables.scss";

@import "bootstrap/scss/bootstrap";

//Icons
@import "bootstrap-icons/font/bootstrap-icons.css";

// Font
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// Extended Bootstrap components
@import "./custom/_type-custom.scss";
@import "./custom/_alerts-custom.scss";
@import "./custom/_buttons-custom.scss";
@import "./custom/_nav-heading.scss";
@import "./custom/_nav-bordered.scss";
@import "./custom/_navbar-utilities.scss";
@import "./custom/_navs-custom.scss";
@import "./custom/_list-group-custom.scss";
@import "./custom/_modals-custom.scss";
@import "./custom/_tables-custom.scss";
@import "./custom/_container-custom.scss";
@import "./custom/_form.scss";

// Custom theme components
@import "./custom/_utilities-spacer.scss";
@import "./custom/_type-custom.scss";
@import "./custom/_icons.scss";
@import "./custom/_flex-table.scss";
@import "./custom/_text-inherit.scss";
@import "./custom/_datepicker.scss";
@import "./custom/_tablesorter.scss";
@import "./custom/_nav-toggler.scss";
@import "./custom/_statlist.scss";
@import "./custom/_statcard.scss";
@import "./custom/_graphs.scss";
@import "./custom/_divider.scss";
@import "./custom/_input-icon.scss";
@import "./custom/_sidebar.scss";
@import "./custom/_iconav.scss";
@import "./custom/_dashhead.scss";
@import "./custom/_buttons-radius.scss";
@import "./custom/_react-select.scss";
@import "./custom/_places-auto-complete.scss";
@import "./custom/_react-toggle.scss";

#root {
  padding-bottom: 75px;
}

.main-content {
  padding-top: 15px;
}

.content-title {
  padding-top: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.nav-fill {
  .nav-item {
    min-width: 100px;
  }
}
.nav-item {
  font-weight: 500;
}

.btn-group {
  z-index: 0;

  button.active {
    background: map-get($theme-colors, "primary");
  }
}

.btn-wrap {
  white-space: normal;
  word-wrap: break-word;
  text-align: left;
}

.dropdown-item {
  cursor: pointer;
}
.dropdown-item:hover {
  text-decoration: none;
}

.text-mosaic {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
}

.image-mosaic {
  display: flex;
  flex-direction: column;
}

.image-mosaic,
.text-mosaic {
  background-color: #ffffff;
  color: #0d0f11;
  border: 1px solid #6c757d;

  .mosaic-heading {
    text-align: center;
    font-size: large;
    font-weight: 700;
  }
  .mosaic-label {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    margin-top: 10px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .mosaic-description {
    text-align: center;
    font-size: small;
    width: 100%;
    white-space: pre-line;
    margin-top: auto !important;
  }
  .mosaic-extra-information {
    text-align: center;
    font-size: small;
    width: 100%;
  }

  &.selected {
    background-color: #d3f8d6;
    border: 1px solid #19ab27;
    box-shadow: inset 0 0 1px 2px #19ab27;
    position: relative;
  }

  &.disabled {
    background-color: #aaaaaa;
  }

  &:hover:not(.selected) {
    background-color: #aaaaaa;
  }

  .img-container {
    padding-bottom: 75%;
    height: 0;
    position: relative;

    img {
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      max-width: 100%;
      max-height: 100%;
      display: block;
      position: absolute;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      /* IE10+ specific styles go here */
      img {
        margin-left: -50% !important;
      }
    }
  }
}

.hr-divider {
  margin-bottom: 18px;

  h3 {
    font-size: large;
    z-index: 0;
  }
}

.page-title {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.5em;

  .subtitle {
    font-size: 0.6em;
  }
}

.form-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5em;
}

.dropzone .dz-preview .dz-error-message {
  display: none !important;
}

.text-break-line {
  white-space: pre-line;
}

.form-group {
  .form-label {
    font-weight: bold;
    font-size: 15px;
  }
  .from-sub-heading {
    font-style: italic;
  }
}

.form-group-md-r {
  .form-label {
    font-weight: bold;
    font-size: 15px;
  }
  .from-sub-heading {
    font-style: italic;
  }
  .form-group {
    margin-bottom: 0rem !important;
  }
  display: -webkit-inline-box;
}

.document-item {
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
}

.clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.bg-none {
  background: transparent;
  border: none;
}

div.input-group.invalid {
  input,
  textarea:required {
    border: 1px solid red;
  }

  input,
  textarea:required:focus {
    border: 1px solid red;
  }
}

.react-select-container.highlighted {
  border: 1px solid red;
  border-radius: 4px;
}

.notification-manager {
  .message {
    white-space: pre-line;
  }
}

.text-lg {
  font-size: large;
}

.border-head {
  border-bottom: 1px solid $gray-500;
}

.border-foot {
  border-top: 1px solid $gray-500;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
}

[id^="ngb-typeahead-"] {
  min-width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .dropdown-item {
    border: 1px solid $gray-600;
  }
}
[id^="ngb-typeahead-"].dropdown-menu {
  padding: 0;

  .show {
    max-height: 500px;
    overflow-y: auto;
  }
}

[id^="ngb-typeahead-"].dropdown-menu.show {
  max-height: 500px;
  overflow-y: auto;
}

.invalid-feedback {
  display: block;
}

.ng-invalid {
  @extend .is-invalid;
}

.ami-form-control.ng-invalid {
  input {
    @extend .is-invalid;
  }

  select {
    @extend .is-invalid;
  }

  ng-multiselect-dropdown span.dropdown-btn {
    @extend .is-invalid;
    border-color: #f05050 !important;
  }
}

div.ami-form-group > input[type="hidden"].ami-form-control.ng-invalid ~ input[type="file"] {
  border: 1px solid #f05050 !important;
}

.offcanvas-size-lg {
  @media only screen and (min-width: 992px) {
    width: 700px !important;
  }
}

.offcanvas-size-xl {
  @media only screen and (min-width: 992px) {
    width: 80vw !important;
  }
}

ngb-offcanvas-panel {
  overflow-y: auto;
}

label.col-form-label {
  font-weight: 300 !important;
}

.box {
  flex-grow: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  min-width: 80px;
  max-width: 120px;
  background: #c4a700;
  color: #212529;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 5px;

  &:hover {
    color: #fff;
  }

  &.active {
    color: #fff;
    background-color: #0ab50a;
  }
}

/************* Quotes *************/
// .btn-group-option-selection {
//   display: inline-block;

//   .btn-option-selection {
//     margin: 0;
//   }

//   label.btn-option-selection {
//     width: 200px;
//     min-height: 80px;
//     white-space: normal;
//     float: left;
//     border-radius: 0;
//   }
// }

// .btn-group-option-selection > :not(.btn-check:first-child) + .btn,
// .btn-group-option-selection > .btn-group-option-selection:not(:first-child) {
//   margin-left: 0 !important;
// }

@import "./bootstrap_customizations";
